export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: number; output: number; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: object; output: object; }
};

export type AdditionalService = {
  __typename?: 'AdditionalService';
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  typeName: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
  valueMoney?: Maybe<Money>;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of Apply */
export type ApplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  responseComment?: InputMaybe<Scalars['String']['input']>;
  selectedRewardOption?: InputMaybe<PartnersInputsRewardOptionType>;
};

/** Autogenerated return type of Apply */
export type ApplyPayload = {
  __typename?: 'ApplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobOffer: Offer;
};

export type AttachedFile = {
  __typename?: 'AttachedFile';
  context: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  downloadUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  largeUrl?: Maybe<Scalars['String']['output']>;
  mediumUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  preview?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuidId: Scalars['String']['output'];
};

export type Attachment = {
  __typename?: 'Attachment';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  previewLargeUrl?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuidId: Scalars['String']['output'];
};

export enum CategoryEnum {
  /** job_offer */
  JobOffer = 'job_offer',
  /** provider_order */
  ProviderOrder = 'provider_order',
  /** shipping */
  Shipping = 'shipping'
}

/** Autogenerated input type of ChangePrimary */
export type ChangePrimaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newPrimaryContactId: Scalars['ID']['input'];
};

/** Autogenerated return type of ChangePrimary */
export type ChangePrimaryPayload = {
  __typename?: 'ChangePrimaryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  provider: Provider;
};

/** Autogenerated input type of ChangeRfqReady */
export type ChangeRfqReadyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ready: Scalars['Boolean']['input'];
};

/** Autogenerated return type of ChangeRfqReady */
export type ChangeRfqReadyPayload = {
  __typename?: 'ChangeRfqReadyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  provider: Provider;
};

/** Autogenerated input type of Clean */
export type CleanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  providerOrderId: Scalars['Int']['input'];
};

/** Autogenerated return type of Clean */
export type CleanPayload = {
  __typename?: 'CleanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  event: Event;
};

export type Color = {
  __typename?: 'Color';
  background?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of ConfirmEmail */
export type ConfirmEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  confirmationToken: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

/** Autogenerated return type of ConfirmEmail */
export type ConfirmEmailPayload = {
  __typename?: 'ConfirmEmailPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  preferredContactType?: Maybe<Scalars['String']['output']>;
  provider: Provider;
  telegram?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  viber?: Maybe<Scalars['String']['output']>;
  wechat?: Maybe<Scalars['String']['output']>;
  whatsapp?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ContactsCreate */
export type ContactsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contact: PartnersInputsContactType;
};

/** Autogenerated return type of ContactsCreate */
export type ContactsCreatePayload = {
  __typename?: 'ContactsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
};

/** Autogenerated input type of ContactsUpdate */
export type ContactsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contact: PartnersInputsContactType;
};

/** Autogenerated return type of ContactsUpdate */
export type ContactsUpdatePayload = {
  __typename?: 'ContactsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
};

export type Counter = {
  __typename?: 'Counter';
  activeTasks: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  sources: Array<MessageSource>;
  unreadMessages: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of CreateTicket */
export type CreateTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<Scalars['String']['input']>>;
  jobOfferId?: InputMaybe<Scalars['Int']['input']>;
  providerOrderId?: InputMaybe<Scalars['Int']['input']>;
  subject: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of CreateTicket */
export type CreateTicketPayload = {
  __typename?: 'CreateTicketPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  ticket: Ticket;
};

export enum CurrencyEnum {
  /** BGN */
  Bgn = 'BGN',
  /** CHF */
  Chf = 'CHF',
  /** CZK */
  Czk = 'CZK',
  /** DKK */
  Dkk = 'DKK',
  /** EUR */
  Eur = 'EUR',
  /** GBP */
  Gbp = 'GBP',
  /** HUF */
  Huf = 'HUF',
  /** ISK */
  Isk = 'ISK',
  /** NOK */
  Nok = 'NOK',
  /** PLN */
  Pln = 'PLN',
  /** RON */
  Ron = 'RON',
  /** SEK */
  Sek = 'SEK',
  /** TRY */
  Try = 'TRY',
  /** USD */
  Usd = 'USD'
}

export type Deal = {
  __typename?: 'Deal';
  certificates?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  customerOrderCode?: Maybe<Scalars['String']['output']>;
  customerOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  deliveryDeadline?: Maybe<Scalars['ISO8601Date']['output']>;
  externalCrmId?: Maybe<Scalars['String']['output']>;
  externalCrmUrl?: Maybe<Scalars['String']['output']>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  googleDriveUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  invoiced?: Maybe<Scalars['Float']['output']>;
  isExpress?: Maybe<Scalars['Boolean']['output']>;
  isImportant: Scalars['Boolean']['output'];
  isMcNeeded?: Maybe<Scalars['Boolean']['output']>;
  isNpg: Scalars['Boolean']['output'];
  isOnHold: Scalars['Boolean']['output'];
  isPreQuoted?: Maybe<Scalars['Boolean']['output']>;
  isSamplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  manualProcessingComment?: Maybe<Scalars['String']['output']>;
  manualProcessingEmails: Scalars['Boolean']['output'];
  manualProcessingFinance: Scalars['Boolean']['output'];
  manualProcessingJobs: Scalars['Boolean']['output'];
  manualProcessingOrderConfirmation: Scalars['Boolean']['output'];
  manualProcessingOutcomingInvoices: Scalars['Boolean']['output'];
  measurementProtocols?: Maybe<Array<Scalars['String']['output']>>;
  number: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['ID']['output']>;
  originalDeal?: Maybe<Deal>;
  originalDealId?: Maybe<Scalars['ID']['output']>;
  originalDeals: Array<Deal>;
  personId?: Maybe<Scalars['ID']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveOrderValue?: Maybe<Scalars['Float']['output']>;
  pipedriveOrderValueMoney: Money;
  pipedrivePipeline?: Maybe<Scalars['String']['output']>;
  pipedriveStatus?: Maybe<Scalars['String']['output']>;
  pipedriveUrl?: Maybe<Scalars['String']['output']>;
  pmId?: Maybe<Scalars['ID']['output']>;
  productionCompleted: Scalars['Boolean']['output'];
  purchaseOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  quoteIsActive: Scalars['Boolean']['output'];
  slackUrl?: Maybe<Scalars['String']['output']>;
  slackUrlRequested?: Maybe<Scalars['Boolean']['output']>;
  stage?: Maybe<Scalars['String']['output']>;
  testDeal: Scalars['Boolean']['output'];
  totalPaidNett?: Maybe<Scalars['Float']['output']>;
  totalPaidNettMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vat: Scalars['Float']['output'];
};

/** Available values for job_offers decision_status field */
export enum DecisionStatusEnum {
  /** Relates to [auto_accepted, accepted] */
  Accepted = 'accepted',
  /** Relates to rejected_by_partner */
  RejectedByPartner = 'rejected_by_partner',
  /** Relates to rejected */
  RejectedByXometry = 'rejected_by_xometry',
  /** Relates to pending */
  WaitingForDecision = 'waiting_for_decision'
}

export type Delivery = {
  __typename?: 'Delivery';
  brutto?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['Int']['output'];
  dealProviderOrderId: Scalars['Int']['output'];
  deliveryType?: Maybe<Scalars['String']['output']>;
  expectedShipmentDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  files?: Maybe<Array<AttachedFile>>;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  length?: Maybe<Scalars['Float']['output']>;
  logisticOperator?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  packagingReportCheck?: Maybe<Scalars['String']['output']>;
  partialDelivery: Scalars['Boolean']['output'];
  receivedDelivery?: Maybe<Scalars['String']['output']>;
  trackingLink?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  width?: Maybe<Scalars['Float']['output']>;
};

export type Dimension = {
  __typename?: 'Dimension';
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  z?: Maybe<Scalars['Float']['output']>;
};

export type EnquiryMeasurementProtocol = {
  __typename?: 'EnquiryMeasurementProtocol';
  allowedProcessIds: Array<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  priceMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Event = {
  __typename?: 'Event';
  content?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventCreatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  eventType?: Maybe<EventType>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type EventType = {
  __typename?: 'EventType';
  actionName?: Maybe<Scalars['String']['output']>;
  constantName: Scalars['String']['output'];
  content: Scalars['JSON']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  readOnly: Scalars['Boolean']['output'];
  section?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  createdAt: Scalars['ISO8601DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Finish = {
  __typename?: 'Finish';
  colors?: Maybe<Array<Color>>;
  id: Scalars['ID']['output'];
  isCustom: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nameVersions: Scalars['JSON']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  priceTag: Scalars['String']['output'];
  processId: Scalars['ID']['output'];
  ralEnabled?: Maybe<Scalars['Boolean']['output']>;
  restrictedMaterials: Array<Scalars['Int']['output']>;
};

export type IncomingInvoice = {
  __typename?: 'IncomingInvoice';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditNote?: Maybe<Scalars['Boolean']['output']>;
  currency: CurrencyEnum;
  dealId: Scalars['Int']['output'];
  dealProviderOrderId: Scalars['Int']['output'];
  files?: Maybe<Array<AttachedFile>>;
  id: Scalars['Int']['output'];
  invoicePositions?: Maybe<Array<InvoicePosition>>;
  isPartial?: Maybe<Scalars['Boolean']['output']>;
  isStorno?: Maybe<Scalars['Boolean']['output']>;
  issuedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  needFixComment?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  paymentDueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  provider?: Maybe<IncomingInvoicesProviderType>;
  providerBankDetails?: Maybe<IncomingInvoicesBankDetailsType>;
  providerBillingAddress?: Maybe<IncomingInvoicesBillingAddressType>;
  providerContact?: Maybe<IncomingInvoicesContactType>;
  providerOrder: ProviderOrder;
  refInvoice?: Maybe<IncomingInvoice>;
  refInvoiceId?: Maybe<Scalars['Int']['output']>;
  relatedShippingsIds: Array<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  status: Scalars['String']['output'];
  totalAmountBrutto?: Maybe<Scalars['Float']['output']>;
  totalAmountBruttoMoney?: Maybe<Money>;
  totalAmountNetto?: Maybe<Scalars['Float']['output']>;
  totalAmountNettoMoney?: Maybe<Money>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validity: Scalars['String']['output'];
  vatAmount?: Maybe<Scalars['Float']['output']>;
  vatAmountMoney?: Maybe<Money>;
  vatAvg?: Maybe<Scalars['Float']['output']>;
  xometryIssuedCreditNote: Scalars['Boolean']['output'];
};

export type IncomingInvoicesBankDetailsType = {
  __typename?: 'IncomingInvoicesBankDetailsType';
  bankAccountHolderName?: Maybe<Scalars['String']['output']>;
  bankCountry?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  swiftbic?: Maybe<Scalars['String']['output']>;
};

export type IncomingInvoicesBillingAddressType = {
  __typename?: 'IncomingInvoicesBillingAddressType';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type IncomingInvoicesContactType = {
  __typename?: 'IncomingInvoicesContactType';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of IncomingInvoicesCreate */
export type IncomingInvoicesCreateInput = {
  attributes: PartnersInputsIncomingInvoiceType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  providerOrderId: Scalars['Int']['input'];
};

/** Autogenerated return type of IncomingInvoicesCreate */
export type IncomingInvoicesCreatePayload = {
  __typename?: 'IncomingInvoicesCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  incomingInvoice: IncomingInvoice;
};

/** Attributes for invoices filter */
export type IncomingInvoicesFilterType = {
  state?: InputMaybe<Scalars['String']['input']>;
};

export type IncomingInvoicesProviderType = {
  __typename?: 'IncomingInvoicesProviderType';
  id?: Maybe<Scalars['Int']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  vatId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of IncomingInvoicesUpdate */
export type IncomingInvoicesUpdateInput = {
  attributes: PartnersInputsIncomingInvoiceType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** Autogenerated return type of IncomingInvoicesUpdate */
export type IncomingInvoicesUpdatePayload = {
  __typename?: 'IncomingInvoicesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  incomingInvoice: IncomingInvoice;
};

/** Autogenerated input type of InitResetPassword */
export type InitResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of InitResetPassword */
export type InitResetPasswordPayload = {
  __typename?: 'InitResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type InputsBulkNotificationSettingType = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<CategoryEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
  productionMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export type InputsMoneyType = {
  amount: Scalars['String']['input'];
  amountCents: Scalars['String']['input'];
  currencyCode: CurrencyEnum;
};

export type InputsNotificationSettingType = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<CategoryEnum>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
  productionMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export enum InputsRfqsOffersResponseStateEnumType {
  /** Canceled */
  Canceled = 'canceled',
  /** Empty */
  Empty = 'empty',
  /** Interested */
  Interested = 'interested',
  /** Rejected */
  Rejected = 'rejected',
  /** Responded */
  Responded = 'responded',
  /** Submitted */
  Submitted = 'submitted'
}

export type InvoicePosition = {
  __typename?: 'InvoicePosition';
  accountingCategory: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currency: CurrencyEnum;
  id?: Maybe<Scalars['Int']['output']>;
  lot?: Maybe<Lot>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  pricePerPiece?: Maybe<Scalars['Float']['output']>;
  pricePerPieceMoney?: Maybe<Money>;
  quantity: Scalars['Int']['output'];
  refId?: Maybe<Scalars['Int']['output']>;
  refType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  vatRate: Scalars['Float']['output'];
};

export type InvoicesPageType = {
  __typename?: 'InvoicesPageType';
  invoices: Array<IncomingInvoice>;
  metadata: Metadata;
};

export type Job = {
  __typename?: 'Job';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['Int']['output'];
  displayId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  nowWatching?: Maybe<Scalars['Int']['output']>;
  number: Scalars['String']['output'];
  positions: Array<Position>;
  publicComment?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  urgentJob?: Maybe<Scalars['Boolean']['output']>;
  valueCurrency: CurrencyEnum;
  xometryNumber: Scalars['String']['output'];
};

/** Available values for job_offers decision_state field */
export enum JobsOffersDecisionStateEnumType {
  /** Accepted */
  Accepted = 'accepted',
  /** Autoaccepted */
  AutoAccepted = 'auto_accepted',
  /** Waiting for decision */
  Pending = 'pending',
  /** Rejected by Xometry */
  Rejected = 'rejected',
  /** Rejected by partner */
  RejectedByPartner = 'rejected_by_partner'
}

/** Available values for job_offers response_state field */
export enum JobsOffersResponseStateEnumType {
  /** Application */
  Application = 'application',
  /** Counteroffer */
  Counteroffer = 'counteroffer',
  /** Empty */
  Empty = 'empty',
  /** Irrelevant */
  Irrelevant = 'irrelevant'
}

export type JobsRewardOptionType = {
  __typename?: 'JobsRewardOptionType';
  attributes: Scalars['JSON']['output'];
  type: Scalars['String']['output'];
};

export type Lot = {
  __typename?: 'Lot';
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  id: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  part: Part;
  partId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  shippedQuantity?: Maybe<Scalars['Int']['output']>;
  toShipQuantity?: Maybe<Scalars['Int']['output']>;
  totalPrice: Scalars['Float']['output'];
  totalPriceMoney: Money;
  totalQuantity?: Maybe<Scalars['Int']['output']>;
  unit: Scalars['String']['output'];
  unitPrice: Scalars['Float']['output'];
  unitPriceMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MasterShipping = {
  __typename?: 'MasterShipping';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  shippings: Array<Shipping>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Material = {
  __typename?: 'Material';
  hardware: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  ingredient?: Maybe<Scalars['String']['output']>;
  materialTypeId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  partMarkings?: Maybe<Scalars['JSON']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  surfaceRoughness?: Maybe<Scalars['JSON']['output']>;
  threading: Scalars['Boolean']['output'];
  tolerance?: Maybe<Scalars['JSON']['output']>;
  weldQuantity: Scalars['Boolean']['output'];
};

export type Message = {
  __typename?: 'Message';
  attachments: Array<Attachment>;
  author: MessageUser;
  authorId: Scalars['ID']['output'];
  authorType: UserEnum;
  containsUnsafeLink: Scalars['Boolean']['output'];
  content?: Maybe<Scalars['String']['output']>;
  contentRaw?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deal?: Maybe<Deal>;
  dealId?: Maybe<Scalars['ID']['output']>;
  dealPage?: Maybe<Scalars['Int']['output']>;
  dealTitle?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  editedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  isBookmark: Scalars['Boolean']['output'];
  isVisibleToClient: Scalars['Boolean']['output'];
  isVisibleToPartner: Scalars['Boolean']['output'];
  isWhatsAppMessageError: Scalars['Boolean']['output'];
  mentions: Array<Scalars['ID']['output']>;
  pinned: Scalars['Boolean']['output'];
  pinnedDirection?: Maybe<PinnedDirectionsEnum>;
  replyOn?: Maybe<MessageReply>;
  source?: Maybe<MessageSource>;
  sourcePage: Scalars['Int']['output'];
  task?: Maybe<Scalars['JSON']['output']>;
  type?: Maybe<MessageTypeEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MessageCollection = {
  __typename?: 'MessageCollection';
  collection: Array<Message>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MessageReply = {
  __typename?: 'MessageReply';
  authorId: Scalars['ID']['output'];
  authorType: UserEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  previewText: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MessageSource = {
  __typename?: 'MessageSource';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  customerLastReadMessageId?: Maybe<Scalars['ID']['output']>;
  customerWhatsAppStatus?: Maybe<WhatsAppStatusEnum>;
  dealId?: Maybe<Scalars['ID']['output']>;
  dealTitle?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isFollowed?: Maybe<Scalars['Boolean']['output']>;
  parent?: Maybe<Scalars['JSON']['output']>;
  partnerLastReadMessageId?: Maybe<Scalars['ID']['output']>;
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
  subscription?: Maybe<Subscription>;
  title?: Maybe<Scalars['String']['output']>;
  unreadMessagesCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum MessageTypeEnum {
  DocumentNote = 'document_note',
  General = 'general',
  IncomingEmail = 'incoming_email',
  OutcomingEmail = 'outcoming_email',
  PricingWizard = 'pricing_wizard',
  RfqOfferSubmitComment = 'rfq_offer_submit_comment',
  RfqProcessComment = 'rfq_process_comment',
  Robot = 'robot',
  TaskCall = 'task_call',
  TaskChangesInDeal = 'task_changes_in_deal',
  TaskCheckStatus = 'task_check_status',
  TaskCheckingMaterialCertificate = 'task_checking_material_certificate',
  TaskCheckpoint = 'task_checkpoint',
  TaskCreateDocument = 'task_create_document',
  TaskDiscussIssue = 'task_discuss_issue',
  TaskDrawing = 'task_drawing',
  TaskGeneral = 'task_general',
  TaskInvoiceIssue = 'task_invoice_issue',
  TaskPlacingIssues = 'task_placing_issues',
  TaskPlacingRepeatDeal = 'task_placing_repeat_deal',
  TaskReplacingRequest = 'task_replacing_request',
  TaskRequestApprove = 'task_request_approve',
  TaskRevenueForecastDiff = 'task_revenue_forecast_diff',
  TaskShipping = 'task_shipping',
  TaskTalkToCustomer = 'task_talk_to_customer',
  TaskTalkToPartner = 'task_talk_to_partner',
  Whatsapp = 'whatsapp'
}

export type MessageUser = {
  __typename?: 'MessageUser';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isCurrent: Scalars['Boolean']['output'];
  isRobot?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: UserEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of MessengerAttachmentsUpload */
export type MessengerAttachmentsUploadInput = {
  attachments: Array<PartnersInputsMessengerAttachmentType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MessengerAttachmentsUpload */
export type MessengerAttachmentsUploadPayload = {
  __typename?: 'MessengerAttachmentsUploadPayload';
  attachments: Array<Attachment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MessengerChannelMarkRead */
export type MessengerChannelMarkReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lastReadMessageId?: InputMaybe<Scalars['ID']['input']>;
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

/** Autogenerated return type of MessengerChannelMarkRead */
export type MessengerChannelMarkReadPayload = {
  __typename?: 'MessengerChannelMarkReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  source: MessageSource;
};

/** Autogenerated input type of MessengerMessageCreate */
export type MessengerMessageCreateInput = {
  attributes: PartnersInputsMessengerMessageType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MessengerMessageCreate */
export type MessengerMessageCreatePayload = {
  __typename?: 'MessengerMessageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageUpdate */
export type MessengerMessageUpdateInput = {
  attributes: PartnersInputsMessengerMessageType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageUpdate */
export type MessengerMessageUpdatePayload = {
  __typename?: 'MessengerMessageUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

export type Metadata = {
  __typename?: 'Metadata';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export type Money = MoneyInterface & {
  __typename?: 'Money';
  amount: Scalars['String']['output'];
  amountCents: Scalars['String']['output'];
  currencyCode: CurrencyEnum;
};

/** Contains price and currency */
export type MoneyInterface = {
  amount: Scalars['String']['output'];
  amountCents: Scalars['String']['output'];
  currencyCode: CurrencyEnum;
};

export type Mutation = {
  __typename?: 'Mutation';
  contactsConfirmEmail?: Maybe<ConfirmEmailPayload>;
  contactsCreate?: Maybe<ContactsCreatePayload>;
  contactsInitResetPassword?: Maybe<InitResetPasswordPayload>;
  contactsRefreshToken?: Maybe<RefreshTokenPayload>;
  contactsResendConfirmation?: Maybe<ResendConfirmationPayload>;
  contactsResetPassword?: Maybe<ResetPasswordPayload>;
  contactsSignIn?: Maybe<SignInPayload>;
  contactsSignOut?: Maybe<SignOutPayload>;
  contactsSignUp?: Maybe<SignUpPayload>;
  contactsUnsubscribeEmail?: Maybe<UnsubscribePayload>;
  contactsUpdate?: Maybe<ContactsUpdatePayload>;
  eventsClean?: Maybe<CleanPayload>;
  eventsUpdate?: Maybe<UpdatePayload>;
  incomingInvoicesCreate?: Maybe<IncomingInvoicesCreatePayload>;
  incomingInvoicesUpdate?: Maybe<IncomingInvoicesUpdatePayload>;
  jobOffersApply?: Maybe<ApplyPayload>;
  jobOffersProposal?: Maybe<ProposalPayload>;
  jobOffersRefuse?: Maybe<RefusePayload>;
  jobOffersReject?: Maybe<RejectPayload>;
  messengerAttachmentsUpload?: Maybe<MessengerAttachmentsUploadPayload>;
  messengerMessageCreate?: Maybe<MessengerMessageCreatePayload>;
  messengerMessageUpdate?: Maybe<MessengerMessageUpdatePayload>;
  messengerSourceMarkRead?: Maybe<MessengerChannelMarkReadPayload>;
  notificationSettingBulkUpdate?: Maybe<NotificationSettingBulkUpdatePayload>;
  notificationSettingCreate?: Maybe<NotificationSettingCreatePayload>;
  notificationSettingUpdate?: Maybe<NotificationSettingUpdatePayload>;
  presetsCreate?: Maybe<PresetsCreatePayload>;
  presetsDelete?: Maybe<PresetsDeletePayload>;
  presetsUpdate?: Maybe<PresetsUpdatePayload>;
  providerOrdersCertificateUpdate?: Maybe<ProviderOrdersCertificateUpdatePayload>;
  providerOrdersMeasurementProtocolUpdate?: Maybe<ProviderOrdersMeasurementProtocolUpdatePayload>;
  providerOrdersProviderOrderNumberUpdate?: Maybe<ProviderOrderNumberUpdatePayload>;
  providerOrdersQualityControlReportUpload?: Maybe<ProviderOrdersQualityControlReportUploadPayload>;
  providersChangePrimary?: Maybe<ChangePrimaryPayload>;
  providersRfqReady?: Maybe<ChangeRfqReadyPayload>;
  providersUpdate?: Maybe<ProvidersUpdatePayload>;
  rfqsOffersUpdate?: Maybe<RfqsOffersUpdatePayload>;
  shippingsCreate?: Maybe<ShippingsCreatePayload>;
  shippingsUpdate?: Maybe<ShippingsUpdatePayload>;
  shippingsUpdateTrackingNumber?: Maybe<ShippingsUpdateTrakingNumberPayload>;
  supportCreateTicket?: Maybe<CreateTicketPayload>;
};


export type MutationContactsConfirmEmailArgs = {
  input: ConfirmEmailInput;
};


export type MutationContactsCreateArgs = {
  input: ContactsCreateInput;
};


export type MutationContactsInitResetPasswordArgs = {
  input: InitResetPasswordInput;
};


export type MutationContactsRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationContactsResendConfirmationArgs = {
  input: ResendConfirmationInput;
};


export type MutationContactsResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationContactsSignInArgs = {
  input: SignInInput;
};


export type MutationContactsSignOutArgs = {
  input: SignOutInput;
};


export type MutationContactsSignUpArgs = {
  input: SignUpInput;
};


export type MutationContactsUnsubscribeEmailArgs = {
  input: UnsubscribeInput;
};


export type MutationContactsUpdateArgs = {
  input: ContactsUpdateInput;
};


export type MutationEventsCleanArgs = {
  input: CleanInput;
};


export type MutationEventsUpdateArgs = {
  input: UpdateInput;
};


export type MutationIncomingInvoicesCreateArgs = {
  input: IncomingInvoicesCreateInput;
};


export type MutationIncomingInvoicesUpdateArgs = {
  input: IncomingInvoicesUpdateInput;
};


export type MutationJobOffersApplyArgs = {
  input: ApplyInput;
};


export type MutationJobOffersProposalArgs = {
  input: ProposalInput;
};


export type MutationJobOffersRefuseArgs = {
  input: RefuseInput;
};


export type MutationJobOffersRejectArgs = {
  input: RejectInput;
};


export type MutationMessengerAttachmentsUploadArgs = {
  input: MessengerAttachmentsUploadInput;
};


export type MutationMessengerMessageCreateArgs = {
  input: MessengerMessageCreateInput;
};


export type MutationMessengerMessageUpdateArgs = {
  input: MessengerMessageUpdateInput;
};


export type MutationMessengerSourceMarkReadArgs = {
  input: MessengerChannelMarkReadInput;
};


export type MutationNotificationSettingBulkUpdateArgs = {
  input: NotificationSettingBulkUpdateInput;
};


export type MutationNotificationSettingCreateArgs = {
  input: NotificationSettingCreateInput;
};


export type MutationNotificationSettingUpdateArgs = {
  input: NotificationSettingUpdateInput;
};


export type MutationPresetsCreateArgs = {
  input: PresetsCreateInput;
};


export type MutationPresetsDeleteArgs = {
  input: PresetsDeleteInput;
};


export type MutationPresetsUpdateArgs = {
  input: PresetsUpdateInput;
};


export type MutationProviderOrdersCertificateUpdateArgs = {
  input: ProviderOrdersCertificateUpdateInput;
};


export type MutationProviderOrdersMeasurementProtocolUpdateArgs = {
  input: ProviderOrdersMeasurementProtocolUpdateInput;
};


export type MutationProviderOrdersProviderOrderNumberUpdateArgs = {
  input: ProviderOrderNumberUpdateInput;
};


export type MutationProviderOrdersQualityControlReportUploadArgs = {
  input: ProviderOrdersQualityControlReportUploadInput;
};


export type MutationProvidersChangePrimaryArgs = {
  input: ChangePrimaryInput;
};


export type MutationProvidersRfqReadyArgs = {
  input: ChangeRfqReadyInput;
};


export type MutationProvidersUpdateArgs = {
  input: ProvidersUpdateInput;
};


export type MutationRfqsOffersUpdateArgs = {
  input: RfqsOffersUpdateInput;
};


export type MutationShippingsCreateArgs = {
  input: ShippingsCreateInput;
};


export type MutationShippingsUpdateArgs = {
  input: ShippingsUpdateInput;
};


export type MutationShippingsUpdateTrackingNumberArgs = {
  input: ShippingsUpdateTrakingNumberInput;
};


export type MutationSupportCreateTicketArgs = {
  input: CreateTicketInput;
};

export type MyRegion = {
  __typename?: 'MyRegion';
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  link: Scalars['String']['output'];
  region: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  active: Scalars['Boolean']['output'];
  category: CategoryEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  material?: Maybe<Tag>;
  productionMethod?: Maybe<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of NotificationSettingBulkUpdate */
export type NotificationSettingBulkUpdateInput = {
  attributesArray: Array<InputsBulkNotificationSettingType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of NotificationSettingBulkUpdate */
export type NotificationSettingBulkUpdatePayload = {
  __typename?: 'NotificationSettingBulkUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notificationSettings: Array<NotificationSetting>;
};

/** Autogenerated input type of NotificationSettingCreate */
export type NotificationSettingCreateInput = {
  attributes: InputsNotificationSettingType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of NotificationSettingCreate */
export type NotificationSettingCreatePayload = {
  __typename?: 'NotificationSettingCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notificationSetting: NotificationSetting;
};

/** Autogenerated input type of NotificationSettingUpdate */
export type NotificationSettingUpdateInput = {
  attributes: InputsNotificationSettingType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of NotificationSettingUpdate */
export type NotificationSettingUpdatePayload = {
  __typename?: 'NotificationSettingUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notificationSetting: NotificationSetting;
};

export type Offer = {
  __typename?: 'Offer';
  active: Scalars['Boolean']['output'];
  allowAutoConfirmation?: Maybe<Scalars['Boolean']['output']>;
  allowCounterofferFrom?: Maybe<Scalars['ISO8601DateTime']['output']>;
  allowDirectShipping?: Maybe<Scalars['Boolean']['output']>;
  archived: Scalars['Boolean']['output'];
  bonus?: Maybe<Scalars['Float']['output']>;
  bonusAvailableAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  bonusMoney?: Maybe<Money>;
  counterofferLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  counterofferValue?: Maybe<Scalars['Float']['output']>;
  counterofferValueMoney?: Maybe<Money>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  decidedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  decidedBy?: Maybe<Scalars['Int']['output']>;
  decisionComment?: Maybe<Scalars['String']['output']>;
  decisionRefusalReason?: Maybe<Scalars['String']['output']>;
  decisionState?: Maybe<JobsOffersDecisionStateEnumType>;
  id: Scalars['Int']['output'];
  isUrgent: Scalars['Boolean']['output'];
  job: Job;
  label?: Maybe<Scalars['String']['output']>;
  leadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  messagesTotal: Scalars['Int']['output'];
  providerId: Scalars['Int']['output'];
  providerOrderId?: Maybe<Scalars['Int']['output']>;
  providerOrderNumber?: Maybe<Scalars['String']['output']>;
  publicComment?: Maybe<Scalars['String']['output']>;
  publicationEnd: Scalars['ISO8601DateTime']['output'];
  publicationStart: Scalars['ISO8601DateTime']['output'];
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  readOnlyReason?: Maybe<Scalars['String']['output']>;
  respondedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  respondedBy?: Maybe<Scalars['Int']['output']>;
  responseComment?: Maybe<Scalars['String']['output']>;
  responseRefusalReason?: Maybe<Scalars['String']['output']>;
  responseState?: Maybe<JobsOffersResponseStateEnumType>;
  rewardOptions: Array<JobsRewardOptionType>;
  selectedRewardOption?: Maybe<JobsRewardOptionType>;
  showPositionPrices?: Maybe<Scalars['Boolean']['output']>;
  totalValue: Scalars['Float']['output'];
  totalValueMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['Float']['output'];
  valueMoney: Money;
  viewed: Scalars['Boolean']['output'];
  zendeskTicketId?: Maybe<Scalars['String']['output']>;
};

/** Attributes for job offers filter */
export type OffersFilterType = {
  decisionStatus?: InputMaybe<DecisionStatusEnum>;
  excludeTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  materialsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  number?: InputMaybe<Scalars['String']['input']>;
  productionMethodsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  responseStatus?: InputMaybe<Scalars['String']['input']>;
  urgentStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OffersPageType = {
  __typename?: 'OffersPageType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  metadata: Metadata;
  offers: Array<Offer>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OffersSimilarPageType = {
  __typename?: 'OffersSimilarPageType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  metadata: PageMetadata;
  offers: Array<SimilarOffer>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Attributes for pagination */
export type OffsetAttributes = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OmsEnquiryPartQuantityOption = {
  __typename?: 'OmsEnquiryPartQuantityOption';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** Direction of sorting */
export enum OrderOptionsEnum {
  /** Ascending order */
  Asc = 'asc',
  /** Descending order */
  Desc = 'desc'
}

export type PageMetadata = {
  __typename?: 'PageMetadata';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Part = {
  __typename?: 'Part';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dimensions?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<AttachedFile>>;
  hsCode?: Maybe<Scalars['String']['output']>;
  hsCodeName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  itemMassKg?: Maybe<Scalars['Float']['output']>;
  material?: Maybe<Scalars['String']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postProcessing?: Maybe<Scalars['String']['output']>;
  processType: Scalars['String']['output'];
  productionRemark?: Maybe<Scalars['String']['output']>;
  samplesComment?: Maybe<Scalars['String']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  samplesQuantity?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Tag>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  volumeMm3?: Maybe<Scalars['Float']['output']>;
};

export type PartRank = {
  __typename?: 'PartRank';
  communication?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  issueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  number: Scalars['String']['output'];
  packing?: Maybe<Scalars['Float']['output']>;
  providerOrderId: Scalars['Int']['output'];
  providerOrderNumber?: Maybe<Scalars['Int']['output']>;
  quality?: Maybe<Scalars['Float']['output']>;
  qualityComment?: Maybe<Scalars['String']['output']>;
  timing?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  verdict?: Maybe<Scalars['String']['output']>;
};

export type PartnersEnquiryProcessType = {
  __typename?: 'PartnersEnquiryProcessType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PartnersInputsAddressType = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type PartnersInputsContactType = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  preferredContactType?: InputMaybe<Scalars['String']['input']>;
  telegram?: InputMaybe<Scalars['String']['input']>;
  viber?: InputMaybe<Scalars['String']['input']>;
  wechat?: InputMaybe<Scalars['String']['input']>;
  whatsapp?: InputMaybe<Scalars['String']['input']>;
};

export type PartnersInputsIncomingInvoiceBankDetailsType = {
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankCountry?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  swiftbic?: InputMaybe<Scalars['String']['input']>;
};

export type PartnersInputsIncomingInvoiceBillingAddressType = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type PartnersInputsIncomingInvoicePositionType = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  accountingCategory: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  pricePerPiece?: InputMaybe<Scalars['Float']['input']>;
  quantity: Scalars['Int']['input'];
  refId?: InputMaybe<Scalars['Int']['input']>;
  refType?: InputMaybe<Scalars['String']['input']>;
  vatRate: Scalars['Float']['input'];
};

export type PartnersInputsIncomingInvoiceProviderType = {
  legalName?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type PartnersInputsIncomingInvoiceType = {
  comment?: InputMaybe<Scalars['String']['input']>;
  creditNote?: InputMaybe<Scalars['Boolean']['input']>;
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  invoicePositions?: InputMaybe<Array<PartnersInputsIncomingInvoicePositionType>>;
  isPartial?: InputMaybe<Scalars['Boolean']['input']>;
  isStorno?: InputMaybe<Scalars['Boolean']['input']>;
  issuedAt: Scalars['String']['input'];
  number: Scalars['String']['input'];
  paymentDueDate?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<PartnersInputsIncomingInvoiceProviderType>;
  providerBankDetails?: InputMaybe<PartnersInputsIncomingInvoiceBankDetailsType>;
  providerBillingAddress?: InputMaybe<PartnersInputsIncomingInvoiceBillingAddressType>;
  refInvoiceId?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnersInputsMessengerAttachmentType = {
  fileBase64Encode: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PartnersInputsMessengerMessageType = {
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  content: Scalars['String']['input'];
  contentRaw?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  isVisibleToClient?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleToPartner?: InputMaybe<Scalars['Boolean']['input']>;
  mentions?: InputMaybe<Array<Scalars['ID']['input']>>;
  replyOnId?: InputMaybe<Scalars['ID']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<MessageTypeEnum>;
};

export type PartnersInputsProviderType = {
  acceptXometryCreditNote?: InputMaybe<Scalars['Boolean']['input']>;
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankCountry?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  billingAddress?: InputMaybe<PartnersInputsAddressType>;
  contact?: InputMaybe<PartnersInputsContactType>;
  email?: InputMaybe<Scalars['String']['input']>;
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  freeCapacity?: InputMaybe<Scalars['Boolean']['input']>;
  freeCapacityFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  languageList?: InputMaybe<Array<Scalars['String']['input']>>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notVatPayer?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  publicComment?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<PartnersInputsAddressType>;
  tags?: InputMaybe<PartnersInputsTagType>;
  vatId?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type PartnersInputsRewardOptionType = {
  attributes: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type PartnersInputsRfqsOfferType = {
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  prices?: InputMaybe<Array<PartnersInputsRfqsOffersPriceType>>;
  providerComment?: InputMaybe<Scalars['String']['input']>;
  responseRefusalReason?: InputMaybe<Scalars['String']['input']>;
  responseState?: InputMaybe<RfqsOffersResponseStateEnumType>;
};

export type PartnersInputsRfqsOffersPriceType = {
  id?: InputMaybe<Scalars['ID']['input']>;
  leadtime?: InputMaybe<Scalars['Int']['input']>;
  providerPrice?: InputMaybe<InputsMoneyType>;
  quantity: Scalars['Int']['input'];
  rfqPositionId: Scalars['ID']['input'];
};

export type PartnersInputsShippingType = {
  brutto: Scalars['Float']['input'];
  deliveryNoteComment?: InputMaybe<Scalars['String']['input']>;
  deliveryType: Scalars['String']['input'];
  expectedShipmentDate?: InputMaybe<Scalars['String']['input']>;
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  height: Scalars['Float']['input'];
  length: Scalars['Float']['input'];
  logisticAggregator?: InputMaybe<Scalars['String']['input']>;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  lots?: InputMaybe<Array<PartnersInputsShippingsLotType>>;
  sourceAddress?: InputMaybe<PartnersInputsShippingsSourceAddressType>;
  state: Scalars['String']['input'];
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  waybillUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  width: Scalars['Float']['input'];
};

export type PartnersInputsShippingsLotType = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type PartnersInputsShippingsSourceAddressType = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type PartnersInputsTagType = {
  certifications?: InputMaybe<Array<Scalars['Int']['input']>>;
  industries?: InputMaybe<Array<Scalars['Int']['input']>>;
  languages?: InputMaybe<Array<Scalars['Int']['input']>>;
  materials?: InputMaybe<Array<Scalars['Int']['input']>>;
  productionMethodFeatures?: InputMaybe<Array<Scalars['Int']['input']>>;
  productionMethods?: InputMaybe<Array<Scalars['Int']['input']>>;
  qualities?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Performance = {
  __typename?: 'Performance';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  ordersInProductionCount?: Maybe<Scalars['Int']['output']>;
  ordersInProductionValue?: Maybe<Scalars['Int']['output']>;
  totalOffers: Scalars['Float']['output'];
  totalOffersAvailable: Scalars['Float']['output'];
  totalOrdersCount?: Maybe<Scalars['Int']['output']>;
  totalOrdersValue?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  urgentCount: Scalars['Float']['output'];
};

export enum PinnedDirectionsEnum {
  All = 'All',
  Finance = 'Finance',
  Logistics = 'Logistics',
  Placing = 'Placing',
  Production = 'Production',
  Sales = 'Sales'
}

export type Position = {
  __typename?: 'Position';
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  id: Scalars['Int']['output'];
  material?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  part: Part;
  partDetails?: Maybe<Scalars['String']['output']>;
  partId: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  priceMoney?: Maybe<Money>;
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Preset = {
  __typename?: 'Preset';
  context: PresetsContextEnumType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  filters: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A list of preset contexts */
export enum PresetsContextEnumType {
  /** Job Offers */
  JobOffers = 'job_offers',
  /** Provider Orders */
  ProviderOrders = 'provider_orders',
  /** Rfq Offers */
  RfqOffers = 'rfq_offers'
}

/** Autogenerated input type of PresetsCreate */
export type PresetsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  context: PresetsContextEnumType;
  filters?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of PresetsCreate */
export type PresetsCreatePayload = {
  __typename?: 'PresetsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  preset: Preset;
};

/** Autogenerated input type of PresetsDelete */
export type PresetsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PresetsDelete */
export type PresetsDeletePayload = {
  __typename?: 'PresetsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  preset: Preset;
};

/** Autogenerated input type of PresetsUpdate */
export type PresetsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PresetsUpdate */
export type PresetsUpdatePayload = {
  __typename?: 'PresetsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  preset: Preset;
};

/** Autogenerated input type of Proposal */
export type ProposalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  counterofferLeadtime?: InputMaybe<Scalars['ISO8601Date']['input']>;
  counterofferValue?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  responseComment?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Proposal */
export type ProposalPayload = {
  __typename?: 'ProposalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobOffer: Offer;
};

export type Provider = {
  __typename?: 'Provider';
  acceptXometryCreditNote: Scalars['Boolean']['output'];
  bankAccount?: Maybe<Scalars['String']['output']>;
  bankAccountHolderName?: Maybe<Scalars['String']['output']>;
  bankCode?: Maybe<Scalars['String']['output']>;
  bankCountry?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  billingAddress: Address;
  competenceFilled?: Maybe<Scalars['Boolean']['output']>;
  contacts: Array<Contact>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  email?: Maybe<Scalars['String']['output']>;
  fileUuidIds?: Maybe<Array<Scalars['String']['output']>>;
  freeCapacity: Scalars['Boolean']['output'];
  freeCapacityFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['Int']['output'];
  isEuropean?: Maybe<Scalars['Boolean']['output']>;
  isLocal?: Maybe<Scalars['Boolean']['output']>;
  isRfqReady?: Maybe<Scalars['Boolean']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  limitsComment?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notVatPayer: Scalars['Boolean']['output'];
  onImprovementComment?: Maybe<Scalars['String']['output']>;
  onboardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  partsRank?: Maybe<Array<PartRank>>;
  performance?: Maybe<Performance>;
  phone?: Maybe<Scalars['String']['output']>;
  pincode?: Maybe<Scalars['String']['output']>;
  poNumberLimit?: Maybe<Scalars['Int']['output']>;
  poValueLimit?: Maybe<Scalars['Float']['output']>;
  poValueLimitMoney?: Maybe<Money>;
  primaryContactId?: Maybe<Scalars['Int']['output']>;
  publicComment?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Rank>;
  shippingAddress: Address;
  state: Scalars['String']['output'];
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ProviderOrder = {
  __typename?: 'ProviderOrder';
  active: Scalars['Boolean']['output'];
  additionalServices?: Maybe<Array<AdditionalService>>;
  allDocumentsFilled?: Maybe<Scalars['Boolean']['output']>;
  allowSelection: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  certificates?: Maybe<Array<ProviderOrdersCertificateType>>;
  conformityCheck?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  deadline: Scalars['ISO8601Date']['output'];
  dealId: Scalars['Int']['output'];
  deliveries?: Maybe<Array<Delivery>>;
  destinationCountry?: Maybe<ProviderOrdersDestinationCountryEnumType>;
  events?: Maybe<Array<Event>>;
  fullyPaidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['Int']['output'];
  invoices?: Maybe<Array<IncomingInvoice>>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  issueDate: Scalars['ISO8601Date']['output'];
  jobOffer?: Maybe<Offer>;
  lots?: Maybe<Array<Lot>>;
  measurementProtocols?: Maybe<Array<ProviderOrdersMeasurementProtocolType>>;
  number: Scalars['String']['output'];
  orderWarnings?: Maybe<Scalars['String']['output']>;
  partsValueNetto?: Maybe<Scalars['Float']['output']>;
  partsValueNettoMoney?: Maybe<Money>;
  paymentTerms?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['Int']['output'];
  providerOrderNumber?: Maybe<Scalars['String']['output']>;
  providerState: Scalars['String']['output'];
  publicComment?: Maybe<Scalars['String']['output']>;
  qcId?: Maybe<Scalars['Int']['output']>;
  qcReportRequired: Scalars['Boolean']['output'];
  qcReports?: Maybe<Array<AttachedFile>>;
  samplesConfirmationStatus?: Maybe<SampleConfirmationStatusEnum>;
  samplesMainProviderOrderId?: Maybe<Scalars['ID']['output']>;
  samplesPoType?: Maybe<SamplesPoTypeEnum>;
  samplesProviderOrderId?: Maybe<Scalars['ID']['output']>;
  servicesValueNetto?: Maybe<Scalars['Float']['output']>;
  servicesValueNettoMoney?: Maybe<Money>;
  shipAddress?: Maybe<ProviderOrdersShipAddressType>;
  shipDirection?: Maybe<ProviderOrdersShipDirectionEnumType>;
  shipIncoterms?: Maybe<ProviderOrdersIncotermsEnumType>;
  shippings?: Maybe<Array<Shipping>>;
  state: Scalars['String']['output'];
  total?: Maybe<ProviderOrdersTotalType>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  xomsalesCustomer?: Maybe<XomRegionEnum>;
};


export type ProviderOrderInvoicesArgs = {
  withoutCanceled?: InputMaybe<Scalars['Boolean']['input']>;
  withoutCreditNotes?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of ProviderOrderNumberUpdate */
export type ProviderOrderNumberUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  providerOrderId: Scalars['Int']['input'];
  providerOrderNumber: Scalars['String']['input'];
};

/** Autogenerated return type of ProviderOrderNumberUpdate */
export type ProviderOrderNumberUpdatePayload = {
  __typename?: 'ProviderOrderNumberUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

export type ProviderOrdersCertificateType = {
  __typename?: 'ProviderOrdersCertificateType';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  files?: Maybe<Array<AttachedFile>>;
  id: Scalars['String']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated input type of ProviderOrdersCertificateUpdate */
export type ProviderOrdersCertificateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  kind: Scalars['String']['input'];
  providerOrderId: Scalars['ID']['input'];
};

/** Autogenerated return type of ProviderOrdersCertificateUpdate */
export type ProviderOrdersCertificateUpdatePayload = {
  __typename?: 'ProviderOrdersCertificateUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

export enum ProviderOrdersDestinationCountryEnumType {
  /** Xometry Europe */
  Germany = 'germany',
  /** Xometry Turkey */
  Turkey = 'turkey',
  /** Xometry UK */
  UnitedKingdom = 'united_kingdom'
}

/** Attributes for provider orders filter */
export type ProviderOrdersFilterType = {
  excludeTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  materialsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  productionMethodsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<ProviderOrdersStateOptionsEnumType>;
};

export enum ProviderOrdersIncotermsEnumType {
  /** DAP */
  Dap = 'DAP',
  /** EXW */
  Exw = 'EXW'
}

export type ProviderOrdersMeasurementProtocolType = {
  __typename?: 'ProviderOrdersMeasurementProtocolType';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  files?: Maybe<Array<AttachedFile>>;
  id: Scalars['String']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated input type of ProviderOrdersMeasurementProtocolUpdate */
export type ProviderOrdersMeasurementProtocolUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  kind: Scalars['String']['input'];
  providerOrderId: Scalars['ID']['input'];
};

/** Autogenerated return type of ProviderOrdersMeasurementProtocolUpdate */
export type ProviderOrdersMeasurementProtocolUpdatePayload = {
  __typename?: 'ProviderOrdersMeasurementProtocolUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

export type ProviderOrdersPageType = {
  __typename?: 'ProviderOrdersPageType';
  metadata: Metadata;
  orders: Array<ProviderOrder>;
};

/** Autogenerated input type of ProviderOrdersQualityControlReportUpload */
export type ProviderOrdersQualityControlReportUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  providerOrderId: Scalars['ID']['input'];
};

/** Autogenerated return type of ProviderOrdersQualityControlReportUpload */
export type ProviderOrdersQualityControlReportUploadPayload = {
  __typename?: 'ProviderOrdersQualityControlReportUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

export type ProviderOrdersShipAddressType = {
  __typename?: 'ProviderOrdersShipAddressType';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum ProviderOrdersShipDirectionEnumType {
  /** P2C */
  P2c = 'p2c',
  /** P2X */
  P2x = 'p2x'
}

/** Available fields for sorting */
export enum ProviderOrdersSortOptionsEnumType {
  /** Creation datetime */
  CreatedAt = 'created_at',
  /** Leadtime expiration datetime */
  Deadline = 'deadline'
}

/** Available sorting parameters */
export type ProviderOrdersSortType = {
  orderBy: OrderOptionsEnum;
  sortBy: ProviderOrdersSortOptionsEnumType;
};

/** Available states for filtering */
export enum ProviderOrdersStateOptionsEnumType {
  /** Accepted */
  Accepted = 'accepted',
  /** Canceled and has shipping with claims */
  Canceled = 'canceled',
  /** Claim */
  Claim = 'claim',
  /** Claim resolved */
  ClaimResolved = 'claim_resolved',
  /** Paid */
  Paid = 'paid',
  /** In production */
  Placed = 'placed',
  /** Need order confirmation */
  ProviderSelected = 'provider_selected',
  /** Shipping */
  Shipping = 'shipping'
}

export type ProviderOrdersTotalType = {
  __typename?: 'ProviderOrdersTotalType';
  itemsCount?: Maybe<Scalars['Int']['output']>;
  positionsCount?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of ProvidersUpdate */
export type ProvidersUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  provider: PartnersInputsProviderType;
};

/** Autogenerated return type of ProvidersUpdate */
export type ProvidersUpdatePayload = {
  __typename?: 'ProvidersUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  provider: Provider;
};

export type Query = {
  __typename?: 'Query';
  /** Fetch tags by context */
  contextTags?: Maybe<Array<Tag>>;
  /** Fetch current provider contact */
  currentContact?: Maybe<Contact>;
  /** Fetch current provider details */
  currentProvider?: Maybe<Provider>;
  dealInvoices: InvoicesPageType;
  dealProviderOrder: ProviderOrder;
  dealProviderOrders: ProviderOrdersPageType;
  dealSources: SourceCollection;
  /** Fetch feature_flag status by name */
  featureFlag: FeatureFlag;
  /** Fetch feature_flags for partners */
  featureFlags: Array<FeatureFlag>;
  /** Fetch current provider offers */
  jobOffer: Offer;
  /** Fetch current provider offers */
  jobOffers: OffersPageType;
  /** Fetch current provider offers similar to an offer */
  jobSimilarOffers: OffersSimilarPageType;
  /** Fetch deal objects */
  messengerArchiveSources: Array<MessageSource>;
  /** Fetch chat user list */
  messengerChatUsers: Array<MessageUser>;
  /** Fetch my counters */
  messengerCounter: Counter;
  /** Fetch job offers as sources */
  messengerJobOffers: Array<MessageSource>;
  /** Fetch deal messages */
  messengerMessages: MessageCollection;
  /** Fetch message */
  messengerMessagesById: Array<Message>;
  /** Fetch deal objects */
  messengerSources: Array<MessageSource>;
  /** Locate region */
  myRegion?: Maybe<MyRegion>;
  /** Fetch settings for notification */
  notificationSettings: Array<NotificationSetting>;
  /** Fetch presets for specific partner */
  presets: Array<Preset>;
  rfqOffer: RfqOffer;
  /** Fetch current provider rfq_offers */
  rfqOffers: RfqOffersPageType;
  shippingTrackingRecords: Array<TrackingRecord>;
  supportTicketEmails: SupportTicketEmailsPageType;
  supportTickets: SupportTicketsPageType;
};


export type QueryContextTagsArgs = {
  contexts?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryDealInvoicesArgs = {
  filter?: InputMaybe<IncomingInvoicesFilterType>;
  offsetAttributes?: InputMaybe<OffsetAttributes>;
};


export type QueryDealProviderOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealProviderOrdersArgs = {
  filter?: InputMaybe<ProviderOrdersFilterType>;
  offsetAttributes?: InputMaybe<OffsetAttributes>;
  sort?: InputMaybe<ProviderOrdersSortType>;
};


export type QueryDealSourcesArgs = {
  providerOrderId: Scalars['Int']['input'];
};


export type QueryFeatureFlagArgs = {
  name: Scalars['String']['input'];
};


export type QueryJobOfferArgs = {
  id: Scalars['Int']['input'];
};


export type QueryJobOffersArgs = {
  filter: OffersFilterType;
  offsetAttributes?: InputMaybe<OffsetAttributes>;
};


export type QueryJobSimilarOffersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offerId: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  similarityType?: InputMaybe<SimilarOffersSimilarityEnumType>;
};


export type QueryMessengerChatUsersArgs = {
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


export type QueryMessengerJobOffersArgs = {
  jobId: Scalars['ID']['input'];
};


export type QueryMessengerMessagesArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessengerMessagesByIdArgs = {
  messageIds: Array<Scalars['ID']['input']>;
};


export type QueryPresetsArgs = {
  contexts?: InputMaybe<Array<PresetsContextEnumType>>;
};


export type QueryRfqOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRfqOffersArgs = {
  filters: RfqOffersFilterType;
  offsetAttributes?: InputMaybe<OffsetAttributes>;
};


export type QueryShippingTrackingRecordsArgs = {
  shippingId: Scalars['ID']['input'];
};


export type QuerySupportTicketEmailsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  ticketId: Scalars['String']['input'];
};


export type QuerySupportTicketsArgs = {
  filter?: InputMaybe<SupportTicketsFilterType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SupportTicketsOrderOptionsType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SupportTicketsSortOptionsType>;
};

export type Rank = {
  __typename?: 'Rank';
  cancelRatio?: Maybe<Scalars['Float']['output']>;
  commIssuesRatio?: Maybe<Scalars['Float']['output']>;
  communication?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  delayedProviderOrdersQuantity?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  overdueRatio?: Maybe<Scalars['Float']['output']>;
  packFailRatio?: Maybe<Scalars['Float']['output']>;
  packing?: Maybe<Scalars['Float']['output']>;
  poQuantity?: Maybe<Scalars['Float']['output']>;
  quality?: Maybe<Scalars['Float']['output']>;
  qualityRatio?: Maybe<Scalars['Float']['output']>;
  timing?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of RefreshToken */
export type RefreshTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  refreshToken: Scalars['String']['input'];
};

/** Autogenerated return type of RefreshToken */
export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of Refuse */
export type RefuseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  responseRefusalReason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Refuse */
export type RefusePayload = {
  __typename?: 'RefusePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobOffer: Offer;
};

/** Autogenerated input type of Reject */
export type RejectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cloneOffer?: InputMaybe<Scalars['Boolean']['input']>;
  decisionRefusalReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of Reject */
export type RejectPayload = {
  __typename?: 'RejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  clonedJobOfferId?: Maybe<Scalars['Int']['output']>;
  jobOffer: Offer;
};

/** Autogenerated input type of ResendConfirmation */
export type ResendConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of ResendConfirmation */
export type ResendConfirmationPayload = {
  __typename?: 'ResendConfirmationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type Rfq = {
  __typename?: 'Rfq';
  attachedFiles?: Maybe<Array<AttachedFile>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  dealId: Scalars['ID']['output'];
  id: Scalars['Int']['output'];
  positions: Array<RfqPosition>;
  publicComment?: Maybe<Scalars['String']['output']>;
  publicationEnd?: Maybe<Scalars['ISO8601DateTime']['output']>;
  publicationStart?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: RfqsStateEnumType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  xometryNumber?: Maybe<Scalars['String']['output']>;
};

export type RfqOffer = {
  __typename?: 'RfqOffer';
  active: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  attachedFiles?: Maybe<Array<AttachedFile>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  decisionRefusalReason?: Maybe<Scalars['String']['output']>;
  decisionState: RfqsOffersDecisionStateEnumType;
  id: Scalars['Int']['output'];
  interestedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  messagesTotal: Scalars['Int']['output'];
  prices: Array<RfqsOffersPriceType>;
  providerComment?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['ID']['output'];
  respondedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  responseRefusalReason?: Maybe<Scalars['String']['output']>;
  responseState: RfqsOffersResponseStateEnumType;
  rfq: Rfq;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  viewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Attributes for rfq_offers filter */
export type RfqOffersFilterType = {
  excludeTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  materialsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  number?: InputMaybe<Scalars['String']['input']>;
  productionMethodsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  responseState?: InputMaybe<InputsRfqsOffersResponseStateEnumType>;
  rfqId?: InputMaybe<Scalars['ID']['input']>;
};

export type RfqOffersPageType = {
  __typename?: 'RfqOffersPageType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  metadata: Metadata;
  offers: Array<RfqOffer>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type RfqPosition = {
  __typename?: 'RfqPosition';
  attachedFiles?: Maybe<Array<AttachedFile>>;
  color?: Maybe<Color>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customColor?: Maybe<Scalars['String']['output']>;
  customFinish?: Maybe<Scalars['String']['output']>;
  customMaterial?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<Dimension>;
  enquiryPartId: Scalars['ID']['output'];
  enquiryQuantityOptions: Array<OmsEnquiryPartQuantityOption>;
  finish?: Maybe<Finish>;
  gauge?: Maybe<Scalars['JSON']['output']>;
  hardwareQuantity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  layerThickness?: Maybe<Scalars['Int']['output']>;
  material?: Maybe<Material>;
  measurementProtocol?: Maybe<EnquiryMeasurementProtocol>;
  measurementProtocolNeeded?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partMarkings?: Maybe<Scalars['JSON']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  process?: Maybe<PartnersEnquiryProcessType>;
  quantity: Scalars['Int']['output'];
  quantityOptions: Array<OmsEnquiryPartQuantityOption>;
  resolution?: Maybe<Scalars['String']['output']>;
  rfqId: Scalars['ID']['output'];
  samplesComment?: Maybe<Scalars['String']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  samplesQuantity?: Maybe<Scalars['Int']['output']>;
  surfaceTitle?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  threadingQuantity?: Maybe<Scalars['Int']['output']>;
  toleranceTitle?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weightKgPerPiece?: Maybe<Scalars['Float']['output']>;
};

export enum RfqsOffersDecisionStateEnumType {
  /** Accepted */
  Accepted = 'accepted',
  /** Negotiating */
  Negotiating = 'negotiating',
  /** Pending */
  Pending = 'pending',
  /** Rejected */
  Rejected = 'rejected'
}

export type RfqsOffersPriceType = {
  __typename?: 'RfqsOffersPriceType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  leadtime?: Maybe<Scalars['Int']['output']>;
  providerPrice?: Maybe<Money>;
  quantity: Scalars['Int']['output'];
  rfqPositionId: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum RfqsOffersResponseStateEnumType {
  /** Canceled */
  Canceled = 'canceled',
  /** Empty */
  Empty = 'empty',
  /** Interested */
  Interested = 'interested',
  /** Rejected */
  Rejected = 'rejected',
  /** Submitted */
  Submitted = 'submitted'
}

/** Autogenerated input type of RfqsOffersUpdate */
export type RfqsOffersUpdateInput = {
  attributes: PartnersInputsRfqsOfferType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsOffersUpdate */
export type RfqsOffersUpdatePayload = {
  __typename?: 'RfqsOffersUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfqOffer: RfqOffer;
};

export enum RfqsStateEnumType {
  /** active */
  Active = 'active',
  /** canceled */
  Canceled = 'canceled',
  /** closed */
  Closed = 'closed',
  /** draft */
  Draft = 'draft',
  /** expired */
  Expired = 'expired'
}

export enum SampleConfirmationStatusEnum {
  /** confirmed */
  Confirmed = 'confirmed',
  /** rejected */
  Rejected = 'rejected',
  /** required */
  Required = 'required'
}

export enum SamplesPoTypeEnum {
  /** main_batch */
  MainBatch = 'main_batch',
  /** samples */
  Samples = 'samples'
}

export type Shipping = {
  __typename?: 'Shipping';
  brutto?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['Int']['output'];
  deliveryNoteComment?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  destinationAddress?: Maybe<ShippingsAddressType>;
  direction: Scalars['String']['output'];
  expectedShipmentDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  files?: Maybe<Array<AttachedFile>>;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  isExport: Scalars['Boolean']['output'];
  isImport: Scalars['Boolean']['output'];
  isImportAndExport: Scalars['Boolean']['output'];
  isPartial?: Maybe<Scalars['Boolean']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  logisticOperator?: Maybe<Scalars['String']['output']>;
  lots?: Maybe<Array<Lot>>;
  masterShipping?: Maybe<MasterShipping>;
  masterShippingId?: Maybe<Scalars['ID']['output']>;
  number: Scalars['String']['output'];
  packagingReportCheckStatus?: Maybe<Scalars['String']['output']>;
  /** @deprecated New Logic for shipping_processing_instruction. XEFIN-1045 */
  processingInstruction?: Maybe<ShippingInstructionEnum>;
  relatedProviderOrders?: Maybe<Array<ProviderOrder>>;
  sourceAddress?: Maybe<ShippingsAddressType>;
  state: Scalars['String']['output'];
  trackingLink?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  warehouseInstruction?: Maybe<WarehouseInstructionEnum>;
  waybills?: Maybe<Array<AttachedFile>>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** A list of shipping processing instruction */
export enum ShippingInstructionEnum {
  /** Repack */
  Repack = 'repack',
  /** Repack for Provider */
  Repack2p = 'repack2p',
  /** Xometry Scrap */
  Scrap = 'scrap',
  /** Xometry Store */
  Store = 'store',
  /** Xometry QC */
  Xqc = 'xqc'
}

export type ShippingsAddressType = {
  __typename?: 'ShippingsAddressType';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ShippingsCreate */
export type ShippingsCreateInput = {
  attributes: PartnersInputsShippingType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  providerOrderId: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingsCreate */
export type ShippingsCreatePayload = {
  __typename?: 'ShippingsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipping: Shipping;
};

/** Autogenerated input type of ShippingsUpdate */
export type ShippingsUpdateInput = {
  attributes: PartnersInputsShippingType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingsUpdate */
export type ShippingsUpdatePayload = {
  __typename?: 'ShippingsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipping: Shipping;
};

/** Autogenerated input type of ShippingsUpdateTrakingNumber */
export type ShippingsUpdateTrakingNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  expectedShipmentDate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  logisticAggregator?: InputMaybe<Scalars['String']['input']>;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  waybillUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of ShippingsUpdateTrakingNumber */
export type ShippingsUpdateTrakingNumberPayload = {
  __typename?: 'ShippingsUpdateTrakingNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipping: Shipping;
};

/** Autogenerated input type of SignIn */
export type SignInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of SignIn */
export type SignInPayload = {
  __typename?: 'SignInPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notVerified?: Maybe<Scalars['Boolean']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SignOut */
export type SignOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SignOut */
export type SignOutPayload = {
  __typename?: 'SignOutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  signOut?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SignUp */
export type SignUpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  provider: PartnersInputsProviderType;
};

/** Autogenerated return type of SignUp */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SimilarOffer = {
  __typename?: 'SimilarOffer';
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  displayId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  jobXometryNumber: Scalars['String']['output'];
  leadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  matchPercentage: Scalars['Int']['output'];
  offerId: Scalars['Int']['output'];
  partsCount: Scalars['Int']['output'];
  previewFile?: Maybe<AttachedFile>;
  totalValue: Scalars['Float']['output'];
  totalValueMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Type of similarity for job_offers */
export enum SimilarOffersSimilarityEnumType {
  Finish = 'finish',
  Material = 'material',
  Smart = 'smart',
  Tech = 'tech'
}

export type Source = {
  __typename?: 'Source';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  positions: Array<InvoicePosition>;
  sourceId: Scalars['Int']['output'];
  sourceName: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SourceCollection = {
  __typename?: 'SourceCollection';
  sources: Array<Source>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastReadMessageId?: Maybe<Scalars['ID']['output']>;
  lastReply?: Maybe<Message>;
  source?: Maybe<MessageSource>;
  unreadCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SupportTicketEmailsPageType = {
  __typename?: 'SupportTicketEmailsPageType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  emails: Array<TicketEmail>;
  emailsCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SupportTicketsFilterType = {
  jobOfferId?: InputMaybe<Scalars['Int']['input']>;
  providerOrderId?: InputMaybe<Scalars['Int']['input']>;
};

export enum SupportTicketsOrderOptionsType {
  /** Ascending order */
  Ascend = 'ascend',
  /** Descending order */
  Descend = 'descend'
}

export type SupportTicketsPageType = {
  __typename?: 'SupportTicketsPageType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  tickets: Array<Ticket>;
  ticketsCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum SupportTicketsSortOptionsType {
  CreatedDate = 'createdDate',
  LastModifiedDate = 'lastModifiedDate',
  Priority = 'priority',
  Status = 'status'
}

export type Tag = {
  __typename?: 'Tag';
  context: Scalars['String']['output'];
  contextName: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  en?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  tr?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Ticket = {
  __typename?: 'Ticket';
  claimId?: Maybe<Scalars['Int']['output']>;
  createdDate: Scalars['ISO8601DateTime']['output'];
  dealId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  jobOfferId?: Maybe<Scalars['Int']['output']>;
  lastModifiedDate: Scalars['ISO8601DateTime']['output'];
  providerOrderId?: Maybe<Scalars['Int']['output']>;
  relatedOrder?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type TicketEmail = {
  __typename?: 'TicketEmail';
  createdDate: Scalars['ISO8601DateTime']['output'];
  fromAddress: Scalars['String']['output'];
  htmlBody: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  textBody: Scalars['String']['output'];
};

export type TrackingRecord = {
  __typename?: 'TrackingRecord';
  createdAt: Scalars['ISO8601DateTime']['output'];
  date?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of Unsubscribe */
export type UnsubscribeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

/** Autogenerated return type of Unsubscribe */
export type UnsubscribePayload = {
  __typename?: 'UnsubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Update */
export type UpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  form: Scalars['JSON']['input'];
  providerOrderId: Scalars['Int']['input'];
};

/** Autogenerated return type of Update */
export type UpdatePayload = {
  __typename?: 'UpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  event: Event;
};

export enum UserEnum {
  /** Represents a Person */
  Client = 'CLIENT',
  /** Represents a ExternalContact */
  ExternalContact = 'EXTERNAL_CONTACT',
  /** Represents a Provider::Contact */
  Partner = 'PARTNER',
  /** Represents a User */
  Xometry = 'XOMETRY'
}

/** A list of WarehouseInstruction */
export enum WarehouseInstructionEnum {
  /** Not Applicable */
  NotApplicable = 'not_applicable',
  /** Repack */
  Repack = 'repack',
  /** Repack for Provider */
  Repack2p = 'repack2p',
  /** Xometry Scrap */
  Scrap = 'scrap',
  /** Xometry Store */
  Store = 'store',
  /** Xometry QC */
  Xqc = 'xqc'
}

export enum WhatsAppStatusEnum {
  DialogClosed = 'dialog_closed',
  DialogInitialized = 'dialog_initialized',
  DialogOpen = 'dialog_open',
  NoVerifiedNumber = 'no_verified_number',
  VerifiedNoMessages = 'verified_no_messages'
}

export enum XomRegionEnum {
  /** Xometry Europe */
  XomEu = 'XomEU',
  /** Xometry Turkey */
  XomTr = 'XomTR',
  /** Xometry UK */
  XomUk = 'XomUK'
}
