import { EMessengerAppName, EMessengerEventBusEvents, TMessengerAppParams } from '@xometry/ui';
import cx from 'classnames';
import { ENV } from 'common/constants/env';
import { DeviceTypes } from 'common/enums/deviceTypes';
import { useDeviceTypeByScreenSize } from 'common/hooks/useDeviceType';
import { useMessengerEventBus } from 'common/hooks/useMessengerEventBus';
import { getAuthToken } from 'common/requests';
import { MicroFrontend } from 'components/MicroFrontend';
import { PreviewModal } from 'components/UI/PreviewImage/PreviewModal';
import React, { FC, useCallback, useEffect, useRef } from 'react';

import { HeaderControls } from './HeaderControls';
import { useOverlayContainerContext } from './OverlayContainer.hooks';
import styles from './OverlayContainer.module.less';
import { EOverlayType, ESide } from './OverlayContainerContext.types';

export const OverlayContainer: FC = () => {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollPositionRef = useRef(0);
  const { side, context, closeSideOverlay: closeSideOverlayFromContext, toggleSide } = useOverlayContainerContext();

  const isOpen = Boolean(side);

  const deviceType = useDeviceTypeByScreenSize();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  const {
    setEventBusRef,
    sendEvent,
    closePreview,
    previewVisibility,
    filesPreview,
    currentFilePreviewId,
  } = useMessengerEventBus();

  const closeSideOverlay = useCallback(() => {
    sendEvent?.(EMessengerEventBusEvents.CLOSE_MESSENGER, {});
    closePreview();
    closeSideOverlayFromContext();
  }, [closePreview, closeSideOverlayFromContext, sendEvent]);

  useEffect(() => {
    if (isOpen && isMobile) {
      scrollPositionRef.current = window.scrollY;
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
      window.scrollTo(0, scrollPositionRef.current);
      document.body.style.top = '';
    }
  }, [isMobile, isOpen]);

  if (!isOpen) {
    return null;
  }

  let messengerAppParams: TMessengerAppParams<EMessengerAppName.PARTNERS_MAIN> | null = null;

  if (context.type === EOverlayType.Messenger) {
    const messengerAppConfig = {
      host: `${ENV.API_ENDPOINT}/partners/graphql`,
      wsHost: ENV.WS_ENDPOINT,
      accessToken: `Bearer ${getAuthToken()}`,
    };

    if (context.initialScreen) {
      messengerAppParams = {
        appName: EMessengerAppName.PARTNERS_MAIN,
        config: messengerAppConfig,
        initialScreen: context.initialScreen,
        setEventBusRef,
        isMobile,
      };
    } else {
      messengerAppParams = {
        appName: EMessengerAppName.PARTNERS_MAIN,
        config: messengerAppConfig,
        initialMessageId: context.initialMessageId,
        setEventBusRef,
        isMobile,
      };
    }
  }

  return (
    <div
      className={cx({
        [styles.wrapper]: true,
        [styles.left]: side === ESide.Left,
        [styles.right]: side === ESide.Right,
        [styles.isMobile]: isMobile,
      })}
    >
      <div
        className={cx({
          [styles.contentContainer]: true,
          [styles.left]: side === ESide.Left,
          [styles.right]: side === ESide.Right,
          [styles.isMobile]: isMobile,
        })}
        ref={contentContainerRef}
      >
        <HeaderControls
          onClose={() => {
            closeSideOverlay();
          }}
          onToggleSide={toggleSide}
          isMobile={isMobile}
          side={side}
        />
        {context.type === EOverlayType.Messenger && messengerAppParams ? (
          <MicroFrontend name="Messenger" host={ENV.MSG_ENDPOINT} appParams={messengerAppParams} />
        ) : null}
      </div>
      {previewVisibility && (
        <PreviewModal
          initFileId={currentFilePreviewId}
          files={filesPreview}
          onClose={() => {
            closePreview();
          }}
        />
      )}
    </div>
  );
};
