import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { ContactFragmentDoc } from '../../fragments/__generated__/contact';
import { ProviderFragmentDoc } from '../../fragments/__generated__/provider';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentContactQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentContactQuery = { __typename?: 'Query', currentContact?: { __typename?: 'Contact', id: number, firstName: string, lastName: string, email: string, position?: string | null, phone?: string | null, telegram?: string | null, viber?: string | null, whatsapp?: string | null, wechat?: string | null, preferredContactType?: string | null, provider: { __typename?: 'Provider', id: number, state: string, competenceFilled?: boolean | null, onboardingCompleted?: boolean | null, publicComment?: string | null, primaryContactId?: number | null, name: string, legalName?: string | null, email?: string | null, vatId?: string | null, phone?: string | null, website?: string | null, pincode?: string | null, locale?: string | null, bankAccount?: string | null, bankName?: string | null, bankCode?: string | null, bankAccountHolderName?: string | null, bankCountry?: string | null, isEuropean?: boolean | null, isRfqReady?: boolean | null, isLocal?: boolean | null, poNumberLimit?: number | null, poValueLimit?: number | null, acceptXometryCreditNote: boolean, freeCapacity: boolean, freeCapacityFreezeUntil?: string | null, fileUuidIds?: Array<string> | null, limitsComment?: string | null, performance?: { __typename?: 'Performance', totalOffersAvailable: number, totalOffers: number, urgentCount: number } | null, tags: Array<{ __typename?: 'Tag', id: number, name: string, context: string }>, billingAddress: { __typename?: 'Address', city?: string | null, country?: string | null, zip?: string | null, address?: string | null }, shippingAddress: { __typename?: 'Address', city?: string | null, country?: string | null, zip?: string | null, address?: string | null } } } | null };


export const CurrentContactDocument = gql`
    query currentContact {
  currentContact {
    ...contact
    provider {
      ...provider
    }
  }
}
    ${ContactFragmentDoc}
${ProviderFragmentDoc}`;

/**
 * __useCurrentContactQuery__
 *
 * To run a query within a React component, call `useCurrentContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentContactQuery(baseOptions?: Apollo.QueryHookOptions<CurrentContactQuery, CurrentContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentContactQuery, CurrentContactQueryVariables>(CurrentContactDocument, options);
      }
export function useCurrentContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentContactQuery, CurrentContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentContactQuery, CurrentContactQueryVariables>(CurrentContactDocument, options);
        }
export type CurrentContactQueryHookResult = ReturnType<typeof useCurrentContactQuery>;
export type CurrentContactLazyQueryHookResult = ReturnType<typeof useCurrentContactLazyQuery>;
export type CurrentContactQueryResult = Apollo.QueryResult<CurrentContactQuery, CurrentContactQueryVariables>;