import {
  ApolloClient,
  ApolloProvider,
  FetchResult,
  from,
  HttpLink,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Observable,
  Operation,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useMonitoringClient } from '@xometry/ui';
import { notification } from 'antd';
import { ENV } from 'common/constants/env';
import {
  ContactsRefreshTokenDocument,
  ContactsRefreshTokenMutation,
} from 'common/mutations/__generated__/contactsRefreshToken';
import {
  clearStorage,
  createRedirectUrl,
  getAuthToken,
  getRefreshToken,
  redirectTo404,
  redirectTo503,
  setAuthToken,
  setRefreshToken,
} from 'common/requests';
import stringify from 'fast-safe-stringify';
import { GraphQLError } from 'graphql/index';
import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react';

interface Props {
  children: ReactNode;
}

const promiseToObservable = (promise: Promise<unknown>) =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (!subscriber.closed) {
          subscriber.next(value);
          subscriber.complete();
        }
      },
      (err) => subscriber.error(err),
    );
  });

export const GQLProvider: FC<Props> = (props) => {
  const { children } = props;
  const monitoringClient = useMonitoringClient();
  const clientRef = useRef<ApolloClient<NormalizedCacheObject> | null>(null);

  const client = useMemo(() => {
    const cache = new InMemoryCache({
      addTypename: true,
    });
    const authLink = setContext((_, { headers }) => {
      const token = getAuthToken();

      return {
        // Why it is typed as any, apollo?
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...headers,
          accept: 'application/json',
          authorization: token ? `Bearer ${token}` : '',
        },
        credentials: 'include',
      };
    });
    const httpLink = new HttpLink({
      uri: (operation) => `${ENV.API_ENDPOINT}/partners/graphql?${operation.operationName}`,
    });

    const networkErrorHandler = (
      operation: Operation,
      networkError: ServerError | ServerParseError,
      forward: NextLink,
    ) => {
      const statusCode = networkError.statusCode;
      const token = getAuthToken();
      const currentRefreshToken = getRefreshToken();

      if (statusCode === 401 || statusCode === 403) {
        clearStorage();

        if (!token || !currentRefreshToken || !clientRef.current) {
          return;
        }

        return promiseToObservable(
          clientRef.current
            .mutate({
              mutation: ContactsRefreshTokenDocument,
              variables: { refreshToken: currentRefreshToken },
            })
            .then((r: FetchResult<ContactsRefreshTokenMutation>) => {
              const data = r.data?.contactsRefreshToken;

              if (data) {
                const { accessToken, refreshToken } = data || {};

                if (accessToken) {
                  setAuthToken(accessToken);
                }

                if (refreshToken) {
                  setRefreshToken(refreshToken);
                }

                operation.setContext({
                  headers: {
                    authorization: token ? `Bearer ${accessToken || ''}` : '',
                    accept: 'application/json',
                  },
                });
              } else {
                window.location.assign(createRedirectUrl());
              }
            })
            .catch((e: unknown) => {
              console.error(e);

              window.location.assign(createRedirectUrl());
            }),
        ).flatMap(() => forward(operation));
      }

      if (statusCode === 404) {
        redirectTo404();

        return;
      }

      if (statusCode === 503) {
        redirectTo503();

        return;
      }

      notification.error({ message: networkError.toString() });

      monitoringClient.captureError(networkError, {
        level: 'warning',
        extras: {
          networkError: stringify(networkError),
        },
      });
    };

    const graphQLErrorHandler = (rawErrors: readonly GraphQLError[]) => {
      rawErrors.forEach((rawError) => {
        notification.error({
          message: 'Error',
          description: rawError.message,
          style: { zIndex: 10001 },
          duration: 3,
        });

        try {
          const message =
            rawError?.message && Array.isArray(rawError?.path)
              ? `[${rawError.path.join('/')}]: ${rawError.message}`
              : rawError?.originalError?.message;

          // Do not log login attempt with incorrect password
          if (message && message.includes('contactsSignIn') && message.includes('Account not found')) {
            return;
          }

          monitoringClient.captureError(message ? new Error(message) : rawError, {
            level: 'warning',
            extras: {
              rawError: stringify(rawError),
            },
          });
        } catch (e: unknown) {
          monitoringClient.captureError(e instanceof Error ? e : new Error(String(e)), {
            level: 'warning',
            extras: {
              customError: e,
              rawError: stringify(rawError),
            },
          });
        }
      });
    };

    const errorLink = onError((errorData) => {
      const { graphQLErrors, networkError, operation, forward } = errorData;

      if (graphQLErrors) {
        graphQLErrorHandler(graphQLErrors);
      }

      if (networkError && 'statusCode' in networkError) {
        networkErrorHandler(operation, networkError, forward);
      }
    });

    const link = from([errorLink, authLink.concat(httpLink)]);

    return new ApolloClient({ cache, link });
  }, [monitoringClient]);

  useEffect(() => {
    clientRef.current = client;
  }, [client]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
