import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type ProviderFieldsFragment = { __typename?: 'Provider', id: number, state: string, competenceFilled?: boolean | null, onboardingCompleted?: boolean | null, publicComment?: string | null, primaryContactId?: number | null, name: string, legalName?: string | null, email?: string | null, vatId?: string | null, phone?: string | null, website?: string | null, pincode?: string | null, locale?: string | null, bankAccount?: string | null, bankName?: string | null, bankCode?: string | null, bankAccountHolderName?: string | null, bankCountry?: string | null, isEuropean?: boolean | null, isRfqReady?: boolean | null, isLocal?: boolean | null, poNumberLimit?: number | null, poValueLimit?: number | null, acceptXometryCreditNote: boolean, freeCapacity: boolean, freeCapacityFreezeUntil?: string | null, fileUuidIds?: Array<string> | null, limitsComment?: string | null };

export const ProviderFieldsFragmentDoc = gql`
    fragment providerFields on Provider {
  id
  state
  competenceFilled
  onboardingCompleted
  publicComment
  primaryContactId
  name
  legalName
  email
  vatId
  phone
  website
  pincode
  locale
  bankAccount
  bankName
  bankCode
  bankAccountHolderName
  bankCountry
  isEuropean
  isRfqReady
  isLocal
  poNumberLimit
  poValueLimit
  acceptXometryCreditNote
  freeCapacity
  freeCapacityFreezeUntil
  fileUuidIds
  limitsComment
}
    `;