import '@xometry/ant-ui/dist/index.css';
import '@xometry/ui/dist/index.css';
import './common/i18n';
import './styles/app.less';

import { MonitoringContext } from '@xometry/ui';
import { XTheme } from '@xometry/uikit';
import { ActionCableProvider } from 'core/app/ActionCableProvider/ActionCableProvider';
import React from 'react';

import { App } from './application';
import { ErrorBoundaryWrapper } from './core/app/ErrorBoundary/ErrorBoundaryWrapper';
import { GQLProvider } from './core/app/GQLProvider/GQLProvider';
import { bootstrap } from './index.utils';

void bootstrap(document.getElementById('root')).then(({ root, monitoringClient }) => {
  root.render(
    <MonitoringContext.Provider value={monitoringClient}>
      <XTheme>
        <GQLProvider>
          <ErrorBoundaryWrapper>
            <ActionCableProvider>
              <App />
            </ActionCableProvider>
          </ErrorBoundaryWrapper>
        </GQLProvider>
      </XTheme>
    </MonitoringContext.Provider>,
  );
});
