import { Layout } from 'antd';
import routes from 'common/routes';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { OverlayContainer } from 'components/OverlayContainer/OverlayContainer';
import RootRedirect from 'components/RootRedirect';
import {
  ConfirmEmailPage,
  FinancePage,
  InitResetPasswordPage,
  JobBoardPage,
  MaintenancePage,
  NotFoundPage,
  OfferPage,
  OnboardingPage,
  OrderPage,
  OrdersPage,
  ProfileDetailsPage,
  ResetPasswordPage,
  RfqsOfferPage,
  SignInPage,
  SignUpPage,
  SupportPage,
  UnsubscribeEmailPage,
} from 'pages';
import { RemoteSignIn } from 'pages/ProfilePage/RemoteSignIn';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

export const Desktop: FC = () => (
  <Layout className="layout">
    <Header />
    <Layout.Content>
      <OverlayContainer />
      <Switch>
        <Route exact path={routes.profileSignIn} component={SignInPage} />
        <Route exact path={routes.profileSignUp} component={SignUpPage} />
        <Route exact path={routes.profileInitResetPassword} component={InitResetPasswordPage} />
        <Route exact path={routes.profileResetPassword} component={ResetPasswordPage} />
        <Route exact path={routes.profileUnsubscribe} component={UnsubscribeEmailPage} />
        <Switch>
          <Route
            exact
            path={[routes.offers, routes.urgentOffers, routes.responses, routes.rfqs, routes.rfqsResponses]}
            component={JobBoardPage}
          />
          <Route exact path={routes.offer(':id')} component={OfferPage} />
          <Route exact path={routes.rfqOffer(':id')} component={RfqsOfferPage} />

          <Route exact path={routes.orders} component={OrdersPage} />
          <Route exact path={routes.order(':id')} component={OrderPage} />
          <Route exact path={`${routes.order(':id') as string}/status`} component={OrderPage} />

          {/* Start Profile section */}

          <Route exact path={routes.profileRemoteSignIn} component={RemoteSignIn} />
          <Route exact path={routes.profileConfirmEmail} component={ConfirmEmailPage} />
          <Route exact path={routes.profileOnboarding} component={OnboardingPage} />
          <Route exact path={routes.profileDetails} component={ProfileDetailsPage} />
          {/* End Profile section */}

          <Route exact path={routes.support} component={SupportPage} />

          <Route exact path={routes.invoices} component={FinancePage} />

          <Route exact path={routes.page404} component={NotFoundPage} />
          <Route exact path={routes.page503} component={MaintenancePage} />
          <Route exact path={routes.root} component={RootRedirect} />
          <Route component={NotFoundPage} />
        </Switch>
      </Switch>
    </Layout.Content>
    <Footer />
  </Layout>
);
