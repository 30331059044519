import { getCurrencySymbolByCode } from '@xometry/ui/dist/utils/money';

import { CURRENT_REGION, RegionsConfig } from './config';
import { RegionData } from './types';

export const toRegion = (probablyRegion?: string | null): RegionData['region'] => {
  if (typeof probablyRegion !== 'string') {
    return CURRENT_REGION;
  }

  const region = probablyRegion.toUpperCase();

  return RegionsConfig[region as keyof typeof RegionsConfig]?.region || CURRENT_REGION;
};

/**
 * Returns regions list.
 * Current region will be first.
 */
export const getRegionsList = () => {
  const list = Object.values(RegionsConfig).filter((config) => config.region !== CURRENT_REGION);

  return [RegionsConfig[CURRENT_REGION], ...list].map((config) => config.region);
};

export const getRegionCurrency = (probablyRegion?: string | null): RegionData['currency'] => {
  return RegionsConfig[toRegion(probablyRegion)].currency;
};

export const getRegionIcon = (probablyRegion?: string | null): RegionData['icon'] => {
  return RegionsConfig[toRegion(probablyRegion)].icon;
};

export const getRegionHost = (probablyRegion?: string | null): RegionData['host'] => {
  return RegionsConfig[toRegion(probablyRegion)].host;
};

export const getRegionCurrencySymbol = (probablyRegion?: string | null): string => {
  return getCurrencySymbolByCode(getRegionCurrency(probablyRegion));
};

export const getRegionSupportedLocales = (probablyRegion?: string | null): RegionData['supportedLocales'] => {
  return RegionsConfig[toRegion(probablyRegion)].supportedLocales;
};

export const getRegionDefaultLocale = (probablyRegion?: string | null): RegionData['defaultLocale'] => {
  return RegionsConfig[toRegion(probablyRegion)].defaultLocale;
};

export const getSupportPhone = (probablyRegion?: string | null): RegionData['supportPhone'] => {
  return RegionsConfig[toRegion(probablyRegion)].supportPhone;
};

export const getSupportEmail = (probablyRegion?: string | null): RegionData['supportEmail'] => {
  return RegionsConfig[toRegion(probablyRegion)].supportEmail;
};

export const getProductionSupportEmail = (probablyRegion?: string | null): RegionData['supportEmail'] => {
  return RegionsConfig[toRegion(probablyRegion)].productionSupportEmail;
};
