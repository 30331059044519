import { lazyLoadPage } from 'common/utils/lazyLoad';

import { ErrorPage as ErrorPageComponent } from './ErrorPage/ErrorPage';

export const ErrorPage = ErrorPageComponent;

export const NotFoundPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "NotFoundPage" */ './NotFound/NotFound').then((r) => r.NotFound),
);

export const MaintenancePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "MaintenancePage" */ './Maintenance').then((r) => r.Maintenance),
);

export const FinancePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "FinancePage" */ './FinancePage/FinancePage').then((r) => r.FinancePage),
);

export const JobBoardPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "JobBoardPage" */ './JobBoardPage/JobBoardPage').then((r) => r.JobBoardPage),
);

export const OfferPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OfferPage" */ './OfferPage/OfferPage').then((r) => r.OfferPage),
);

export const RfqsOfferPage = lazyLoadPage((/* webpackChunkName: "RfqsOfferPage" */) =>
  import('./RfqsOfferPage').then((r) => r.RfqsOfferPage));

export const OrderPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrderPage" */ './OrderPage/OrderPage').then((r) => r.OrderPage),
);

export const OrdersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrdersPage" */ './OrdersPage/OrdersPage').then((r) => r.OrdersPage),
);

export const SupportPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "SupportPage" */ './SupportPage').then((r) => r.SupportPage),
);

export const ProfileDetailsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ProfileDetailsPage" */ './ProfilePage/Details').then((r) => r.DetailsPage),
);

export const ConfirmEmailPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ConfirmEmailPage" */ './ConfirmEmailPage').then((r) => r.ConfirmEmailPage),
);

export const InitResetPasswordPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "InitResetPasswordPage" */ './InitResetPasswordPage').then(
    (r) => r.InitResetPasswordPage,
  ),
);

export const ResetPasswordPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ResetPasswordPage" */ './ResetPasswordPage').then((r) => r.ResetPasswordPage),
);

export const OnboardingPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OnboardingPage" */ './OnboardingPage').then((r) => r.OnboardingPage),
);

export const SignInPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "SignInPage" */ './SignInPage').then((r) => r.SignInPage),
);

export const SignUpPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "SignUpPage" */ './SignUpPage').then((r) => r.SignUpPage),
);

export const UnsubscribeEmailPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "UnsubscribeEmailPage" */ './UnsubscribeEmailPage').then((r) => r.UnsubscribeEmailPage),
);
